<template>
  <section
    v-if="value"
    class="ui-account-modal-container"
    data-testid="elem_vrp_000745"
    @click="onBackDrop"
  >
    <div class="ui-account-modal-container__wrapper" data-testid="elem_vrp_000746" @click.stop>
      <div class="ui-account-modal-container__header" data-testid="elem_vrp_000747">
        <svg v-if="isBackIcon" class="ui-account-modal-container__icon-back" xmlns="http://www.w3.org/2000/svg"
             width="24" height="24" viewBox="0 0 24 24" data-testid="elem_vrp_000750" fill="none" @click="emit('back')"
        >
          <path d="M20 12H4M4 12L10 6M4 12L10 18" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"
          />
        </svg>
        <svg class="ui-account-modal-container__icon-close" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             viewBox="0 0 24 24" fill="none" @click="emit('close')" data-testid="elem_vrp_000751"
        >
          <path d="M18 6.00005L6.00005 18M6 6L12 12L18 18" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="ui-account-modal-container__container" data-testid="elem_vrp_000748">
        <h3 class="ui-account-modal-container__title stl-color-white" data-testid="elem_vrp_000749">
          {{ title }}
        </h3>
        <slot/>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useConditionStore } from "~/stores/condition"

interface Props {
  value: boolean
  title: string
  isBackIcon?: boolean
  onBackdropClose?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  title: '',
  isBackIcon: false,
  onBackdropClose: true,
})

const {
  value,
  title,
  isBackIcon,
  onBackdropClose,
} = toRefs(props)

const emit = defineEmits<{
  back: []
  close: []
}>()

const onBackDrop = () => {
  if (onBackdropClose.value) {
    emit('close')
  }
}

const conditionStore = useConditionStore()
conditionStore.activeBody(value.value)
watch(value, () => {
  conditionStore.activeBody(value.value)
})
</script>

<style lang="scss">
/* download-modal */
.ui-account-modal-container {
  cursor: pointer;
  position: fixed;
  inset: 0;
  border-bottom: 0;
  background-color: map-get($tonePalette, 'tone_1');
  z-index: 20;

  &__wrapper {
    cursor: auto;
    max-width: 100%;
    width: 400rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20rem;
    background-color: map-get($grayPalette, 'color_12');

    @include bp-mobile('max') {
      width: 336rem;
    }
  }

  &__header {
    cursor: pointer !important;
    padding: 20rem 32rem 14rem 32rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    @include bp-mobile('max') {
      padding: 20rem 24rem 14rem 24rem;
    }
  }

  &__icon-back {
    margin-right: auto;
    cursor: pointer !important;
    color: map-get($grayPalette, 'color_ff');
    max-width: 24rem;
    width: 24rem;
    height: 24rem;
  }

  &__icon-close {
    cursor: pointer !important;
    max-width: 24rem;
    width: 24rem;
    height: 24rem;
    color: map-get($grayPalette, 'color_ff');
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 0 36rem 36rem;

    @include bp-mobile('max') {
      padding: 0 24rem 36rem;
    }
  }

  &__title {
    margin: 0;
    color: map-get($grayPalette, 'color_ff');
    font-size: 18rem;
    font-weight: 600;
    line-height: 27rem;

  }

  .button {
    text-transform: unset !important;
    height: 50rem;
  }

  .button_primary {
    margin-top: 24rem;
  }

  .button_secondary {
    margin-top: 12rem;
    background: map-get($grayPalette, 'color_28') !important;
  }

  &__image {
    margin-top: 24rem;
    height: 160rem;
  }

  &__paragraph {
    margin: 24rem 0 0;
    color: map-get($grayPalette, 'color_9b');
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &__paragraph--12 {
    margin: 12rem 0 0;
  }

  &__select {
    margin: 12rem 0 0;
    display: grid;
    height: 56rem !important;

    .field {
      display: grid !important;
    }
  }
}
</style>
